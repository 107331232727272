import React from "react";
import "./home.css";
import Me from "../../assets/avatar.png";
import HeaderSocials from "./HeaderSocials";
import ScrollDown from "./ScrollDown";
import Shapes from "./Shapes";
import Typed from "typed.js";

const Home = () => {
  // Create reference to store the DOM element containing the animation
  const el = React.useRef(null);

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "a Web Developer",
        "a Bassist",
        "a Web Designer",
        "a FullStack Developer",
        "a Web Application Developer",
        "an Ordinary People",
        "an Ordinary People",
        "an Ordinary People",
      ],
      typeSpeed: 200,
      backSpeed: 100,
      startDelay: 800,
      loop: true,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);
  return (
    <section className="home container" id="home">
      <div className="intro">
        <img
          src={Me}
          alt=""
          className="home__img"
          data-aos="flip-left"
          data-aos-delay="100"
          data-aos-duration="1000"
        />
        <h1 className="home__name" data-aos="fade-right" data-aos-delay="300">
          Ahda Firly Barori
        </h1>
        I'm <span className="home__education" ref={el}></span>
        <HeaderSocials />
        <a
          href="#about"
          className="btn fa-beat"
          data-aos="zoom-out"
          data-aos-delay="500"
        >
          Hire Me
        </a>
        <ScrollDown />
      </div>

      <Shapes />
    </section>
  );
};

export default Home;
