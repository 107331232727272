
const Spotify = () => {
    return (
        <section className="spotify container section">
            <h2 className="section__title"
                data-aos="zoom-out-right"
                data-aos-delay="100">
                My Spotify Playlist
            </h2>
            <div>
                <iframe 
                    style={{ borderRadius: '12px' }} 
                    title="adh playlist" 
                    src="https://open.spotify.com/embed/playlist/2kt6My7uSa2b8H4X9TjBg6?utm_source=generator" 
                    width="100%" 
                    height="352" 
                    frameBorder="0" 
                    allowFullScreen 
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                    loading="lazy"
                ></iframe>
            </div>
        </section>
    )
}

export default Spotify;
