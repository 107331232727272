import React from "react";
import "./about.css";
import Image from "../../assets/avatar2.png";
import AboutBox from "./AboutBox";
import Typed from "typed.js";

const About = () => {
  // Create reference to store the DOM element containing the animation
  const el = React.useRef(null);

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "I am Ahda Firly Barori, web developer from Sumenep, Indonesia. I have rich experience in web site design and building and customization since Junior High School, also I am good at Back-End. Btw i use React.js for this portfolio.",
      ],
      typeSpeed: 25,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);
  return (
    <section className="about container section" id="about">
      <h2
        className="section__title"
        data-aos="zoom-out-right"
        data-aos-delay="100"
      >
        About Me
      </h2>

      <div className="about__container grid">
        <img
          src={Image}
          alt=""
          className="about__img"
          data-aos="flip-up"
          data-aos-delay="200"
        />

        <div
          className="about__data grid"
          data-aos="zoom-in-left"
          data-aos-delay="250"
        >
          <div className="about__info">
            <p className="about__description">
              <span ref={el}></span>
            </p>
            <a href="https://wa.link/uekxc3" className="btn">
              Contact Me!
            </a>
          </div>
          <div className="about__skills grid">
            <div className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">Development</h3>
                <span className="skills__number">90%</span>
              </div>
              <div className="skills__bar">
                <span className="skills__percentage development"></span>
              </div>
            </div>
            <div className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">UI/UX design</h3>
                <span className="skills__number">80%</span>
              </div>
              <div className="skills__bar">
                <span className="skills__percentage ui__design"></span>
              </div>
            </div>
            <div className="skills__data">
              <div className="skills__titles">
                <h3 className="skills__name">Photography</h3>
                <span className="skills__number">60%</span>
              </div>
              <div className="skills__bar">
                <span className="skills__percentage photography"></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AboutBox />
    </section>
  );
};

export default About;
