const Data = [
  {
    id: 1,
    category: "education",
    icon: "icon-graduation",
    year: "2012 - 2017",
    title: "Elementary School",
    desc: "In that year I started to like things related to logic and always worked on riddles or questions related to logic.",
  },
  {
    id: 2,
    category: "education",
    icon: "icon-graduation",
    year: "2017 - 2020",
    title: "Junior High School",
    desc: "At that time I started to have the courage to use a laptop as a medium for me to learn programming.",
  },
  {
    id: 3,
    category: "education",
    icon: "icon-graduation",
    year: "2020 - 2023",
    title: "Senior High School",
    desc: "Until when I was in the 3rd grade of high school, I managed to create a simple web application for my extracurricular, namely online attendance, which already uses user and admin.",
  },
  {
    id: 4,
    category: "experience",
    icon: "icon-briefcase",
    year: "2018 - present",
    title: "Web Designer",
    desc: "In middle school, my interest and skills in web development continued to grow. I started to understand more complex concepts in HTML and CSS, and started learning the JavaScript programming language to add interactivity to web pages.",
  },
  {
    id: 5,
    category: "experience",
    icon: "icon-briefcase",
    year: "2020 - present",
    title: "Front-End Developer",
    desc: "During high school, my interest and dedication to web development grew. I deepened my knowledge of HTML, CSS, JavaScript, and PHP, and started exploring popular web frameworks and libraries such as Bootstrap, Sass, Tailwind to speed up the development process on Front-End Developer.",
  },
  {
    id: 6,
    category: "experience",
    icon: "icon-briefcase",
    year: "2022 - present",
    title: "Back-End Developer",
    desc: "At that time, I was also involved in various extracurricular projects that involved creating websites for school activities. I learned about project management, team collaboration, and how to approach web design and development from a more holistic perspective. Until I succeeded in creating a web-based application using native PHP",
  },
];

export default Data;
