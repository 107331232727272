import React from "react";

const AboutBox = () => {
  return (
    <div className="about__boxes grid">
      <div className="about__box" data-aos="fade-right" data-aos-delay="150">
        <i className="about__icon icon-fire"></i>

        <div>
          <h3 className="about__title">10</h3>
          <span className="about__subtitle">Project Completed</span>
        </div>
      </div>
      <div className="about__box" data-aos="fade-left" data-aos-delay="150">
        <i className="about__icon icon-cup"></i>

        <div>
          <h3 className="about__title">325</h3>
          <span className="about__subtitle">Cup of coffee</span>
        </div>
      </div>
      <div className="about__box" data-aos="fade-right" data-aos-delay="200">
        <i className="about__icon icon-people"></i>

        <div>
          <h3 className="about__title">7</h3>
          <span className="about__subtitle">Satisfied clients</span>
        </div>
      </div>
      <div className="about__box" data-aos="fade-left" data-aos-delay="200">
        <i className="about__icon icon-badge"></i>

        <div>
          <h3 className="about__title">8</h3>
          <span className="about__subtitle">Programming Languange</span>
        </div>
      </div>
    </div>
  );
};

export default AboutBox;
