import React from "react";
import "./contact.css";

const Contact = () => {
  return (
    <section className="contact container section" id="contact">
      <h2
        className="section__title"
        data-aos="zoom-out-right"
        data-aos-delay="100"
      >
        Get In Touch
      </h2>

      <div className="contact__container grid">
        <div
          className="contact__info"
          data-aos="flip-right"
          data-aos-delay="200"
        >
          <h3 className="contact__title">Let's talk about everything!</h3>
          <p className="contact__details">
            Don't like forms? Send me an email. 👋
          </p>
        </div>
        <div className="alert-form d-none">
          <div className="alert alert--success">
            <i className="fa fa-check-circle fa-2xl icon"></i>
            <div className="content">
              <div className="title">Send Message Success!</div>
              <div className="body">Thanx for giving me input ^_^</div>
            </div>
          </div>
        </div>

        <form action="" className="contact__form" name="adh-porto">
          <div className="contact__form-group">
            <div
              className="contact__form-div"
              data-aos="fade-right"
              data-aos-once="true"
              data-aos-delay="250"
            >
              <input
                type="text"
                className="contact__form-input"
                placeholder="Insert your name"
                name="nama"
              />
            </div>

            <div
              className="contact__form-div"
              data-aos="fade-left"
              data-aos-once="true"
              data-aos-delay="300"
            >
              <input
                type="email"
                className="contact__form-input"
                placeholder="Insert your email"
                name="email"
              />
            </div>
          </div>

          <div
            className="contact__form-div"
            data-aos="fade-right"
            data-aos-once="true"
            data-aos-delay="350"
          >
            <input
              type="text"
              className="contact__form-input"
              placeholder="Insert your subject"
              name="subject"
            />
          </div>

          <div
            className="contact__form-div contact__form-area"
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-offset="25"
            data-aos-once="true"
          >
            <textarea
              name="message"
              id=""
              cols="30"
              rows="10"
              className="contact__form-input"
              placeholder="Write your message"
            ></textarea>
          </div>
          <button className="btn btn-kirim" type="submit">
            Send Message
          </button>
          <button className="btn d-none btn-loading" type="button">
            <span className="loader"></span>
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
