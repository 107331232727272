import React from "react";

const HeaderSocials = () => {
  return (
    <div className="home__socials fa-shake">
      <a
        href="https://instagram.com/adhfry.r"
        className="home__socials-link"
        target="_blank"
      >
        <i class="fa-brands fa-instagram"></i>
      </a>
    </div>
  );
};

export default HeaderSocials;
