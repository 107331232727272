import React from "react";

const Card = (props) => {
  return (
    <div
      className="timeline__item"
      data-aos="zoom-in-right"
      data-aos-delay="50"
    >
      <i className={props.icon}></i>
      <span
        className="timeline__date"
        data-aos="fade-down"
        data-aos-delay="200"
      >
        {props.year}
      </span>
      <h3 className="timeline__title" data-aos="fade-down" data-aos-delay="500">
        {props.title}
      </h3>
      <p className="timeline__text" data-aos="fade-right" data-aos-delay="1000">
        {props.desc}
      </p>
    </div>
  );
};

export default Card;
