import React from "react";
import "./testimonials.css";
import Image1 from "../../assets/aji.png";
import Image4 from "../../assets/aila.png";
import Image3 from "../../assets/anonimgirl.png";

// import Swiper core and required modules
import { Pagination } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const data = [
  {
    id: 1,
    image: Image1,
    title: "Aji Taufani Ismail",
    subtitle: "Owner Mail Production",
    comment:
      "I am very satisfied with the results, it works quickly too. I was happy to meet him and be able to help him solve the MAIL PRODUCTION problem at that time. Next Project I will come back here again. Good luck! 👍",
  },
  {
    id: 2,
    image: Image4,
    title: "Atila Luciyanti",
    subtitle: "Front End at TaniPro",
    comment:
      "Ahda has consistently demonstrated exceptional coding skills and attention to detail throughout our project. His ability to solve complex technical challenges and meet deadlines has been invaluable. Overall, Ahda's programming expertise is a valuable asset to our team. 👍",
  },
  {
    id: 3,
    image: Image3,
    title: "Cindy Yulia Kartika Sari",
    subtitle: "COO at TaniPro",
    comment:
      "Ahda is a brilliant programmer with high ambitions and exceptional diligence in his work. Ahda ability to complete tasks is outstanding, making his an excellent teammate. 👍",
  },
];

const Testimonials = () => {
  return (
    <section className="testimonials container section">
      <h2
        className="section__title"
        data-aos="zoom-out-right"
        data-aos-delay="100"
      >
        Clients & Reviews
      </h2>

      <Swiper
        className="testimonials__container grid"
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={30}
        slidesPerView={1}
        loop={true}
        grabCursor={true}
        pagination={{ clickable: true }}
      >
        {data.map(({ id, image, title, subtitle, comment }) => {
          return (
            <SwiperSlide className="testimonials__item" key={id}>
              <div className="thumb">
                <a href={image} target="_blank" rel="noreferrer">
                  <img src={image} alt="" />
                </a>
              </div>
              <h3 className="testimonials__title">{title}</h3>
              <span className="subtitle">{subtitle}</span>
              <div className="comment">{comment}</div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonials;
