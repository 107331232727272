import React from "react";
import "./services.css";
import Image1 from "../../assets/service-1.svg";
import Image2 from "../../assets/service-2.svg";
import Image3 from "../../assets/service-3.svg";

const data = [
  {
    id: 1,
    image: Image1,
    title: "UI/UX design",
    description:
      "The main goal of UI is to create a display that is attractive, intuitive, and easy to understand, so that users can interact with interface elements comfortably.",
  },
  {
    id: 2,
    image: Image2,
    title: "Web Development",
    description:
      "Web developers use the latest programming languages ​​and technologies to create interactive, responsive and engaging user experiences in web browsers.",
  },
  {
    id: 3,
    image: Image3,
    title: "Photography",
    description:
      "Photography provides a strong visual element and can enhance the aesthetics of a website. High-quality images can attract users' attention and create a professional impression.",
  },
];

const Services = () => {
  return (
    <section className="services container section" id="services">
      <h2
        className="section__title"
        data-aos="zoom-out-right"
        data-aos-delay="100"
      >
        Services
      </h2>

      <div className="services__container grid" data-aos="fade">
        {data.map(({ id, image, title, description }) => {
          return (
            <div className="services__card" key={id}>
              <img
                src={image}
                alt=""
                className="services__img"
                data-aos="flip-right"
                data-aos-delay="200"
              />

              <h3
                className="services__title"
                data-aos="flip-down"
                data-aos-delay="250"
              >
                {title}
              </h3>
              <p
                className="services__description"
                data-aos="zoom-out-down"
                data-aos-delay="300"
              >
                {description}
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Services;
